<template>

	<div class="wrap p-sticker">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="$router.back()"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">칭찬스티커</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="sticker-head">
					<div class="learn-path">
						<select name="" id="" v-model="cidx" @change="selectCourse">
							<option :value="item.cidx" v-for="(item, index) in courseList">{{ item.shortTitle }}</option>
						</select>
						<select name="" id="" v-model="emIdx" v-if="mainList.length > 0">
							<option :value="item.emIdx" v-for="(item, index) in mainList" >{{ item.shortTitle }}</option>
						</select>
						<strong v-if="eduMainVO != null">{{ eduMainVO.eduTitle}}</strong>
					</div>
					<div class="sticker-guide">
						<a href="javascript:;" @click="openGuide">
							<i></i>
							<span>스티커는 언제 받아요?</span>
						</a>
					</div>
				</div>
				<div class="sticker-cont" v-if="eduMainVO != null">
					<p class="guide-text"><b>{{ getSessionProc().userName }}</b> 학생은 <span class="ty1">{{ eduMainVO.shortTitle }}</span>에서 총 <span class="ty2">{{ recordCount }}</span>개의 칭찬 스티커를 모았습니다.</p>
					<div class="swiper sticker-list">
						<ul class="swiper-wrapper">
							<li class="swiper-slide" v-for="(item, index) in recordList">
								<img :src="'/assets/images/learning/img_sticker_' + $options.filters.slice1to10(item.seq) + '.png'" alt="">
								<span class="date">{{ item.recordDate | dateFilter }}</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "mySticker",
	data () {

		const swiper = () => {
			new this.Swiper(".p-sticker .sticker-list", {
				slidesPerView: "auto",
				spaceBetween: 40,
				freeMode: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					1024: {
						centeredSlides: true,
					},
				},
			});
		}

		return {
			swiper,

			params: this.pageUtils.Params("shortTitle,ASC"),
			cidx: null,
			emIdx: null,

			recentAnswer: null,
			courseList: [],
			mainList: [],
			eduMainVO: null,

			recordCount: 0,
			recordList: [],
		}
	},
	created () {
		this.getOpenCourse();
		this.getLastStep();
	},
	mounted () {

	},
	methods: {


		/**
		 * 사용자의 열린 교과정 조회
		 */
		getOpenCourse() {

			this.$eventBus.$emit("startProgress")

			const self = this

			this.params.openPrevCourse = false
			this.params.openPrevMain = true
			this.params.cateCode = "200101"
			this.axiosCaller.get(self, this.APIs.EDU_COURSE + "/getOpenCourseList", this.params, (res) => {

				const result = res.data
				if (result.status === "ok") {

					this.courseList = result.list
					for (let i = this.courseList.length - 1; i >= 0; i--) {
						if ( this.courseList[i].isOpen ) {
							this.courseList[i].isActive = true;
							this.cidx = this.courseList[i].cidx
							this.mainList = this.courseList[i].eduMainList
							break;
						}
					}

				} else {
					this.swalUtils.gritter(result.status, result.msg, "error")
				}

				this.$eventBus.$emit("doneProgress")
			})

		},


		/**
		 * 사용자의 최근 답변 데이터 조회
		 */
		getLastStep() {

			const self = this
			this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getLastStep", {
				userId: this.getLoginId(),
				cateCode: "200101"
			}, res => {
				const result = res.data
				if (result.status === "ok") {

					self.recentAnswer = {
						answerList: result.answerList,
						eduCourseVO: result.eduCourseVO,
						eduMainVO: result.eduMainVO,
						contentList: result.eduMainVO.contentList,
					}

					self.cidx = result.eduCourseVO.cidx;
					self.emIdx = result.eduMainVO.emIdx;
					self.eduMainVO = result.eduMainVO;
					//self.getStickers( self.emIdx );
				}
			})
		},


		/**
		 *  발급받은 스티커 조회
		 * @param _emIdx
		 */
		getStickers (_emIdx) {

			const self = this
			this.axiosCaller.get( this, this.APIs.EDU_REC + "/getRecordList", {
				emIdx: _emIdx,
				recordMode: 'sticker',
			}, (res) => {
				const result = res.data
				if ( result.status === "ok" ) {
					self.recordList = result.list
					self.recordCount = result.count

					this.$nextTick(() => {
						this.swiper();
					})
				} else {
					self.swalUtils.gritter(result.status, result.msg)
				}
			})

		},



		selectCourse( e ) {
			this.courseList.forEach(course => {
				if ( course.cidx === parseInt( e.target.value ) ) {
					this.mainList = course.eduMainList
				}
			})

			if ( this.mainList.length > 0 ) {
				this.emIdx = this.mainList[0].emIdx
			}
		},

		selectMain( e ) {
			this.emIdx = e.target.emIdx
		},



		openGuide () {
			const self = this
			const url = self.APIs.BASE_URL + "/education/mine/stickerMsg";
			const target = "stickerMsg";
			const options = "";
			this.appOpen({
				url: url,
				target: target,
				options: options,
			})
		},

	},
	watch: {

		emIdx (newVal) {
			this.getStickers( newVal )
			this.mainList.forEach(val => {
				if ( val.emIdx == newVal ) {
					this.eduMainVO = val
				}
			})
		},
		eduMainVO: function (n) {
			console.log(n)
		}

	}
}
</script>

<style scoped>

</style>