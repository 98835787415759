import { render, staticRenderFns } from "./mySticker.vue?vue&type=template&id=2224257f&scoped=true"
import script from "./mySticker.vue?vue&type=script&lang=js"
export * from "./mySticker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2224257f",
  null
  
)

export default component.exports